import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogTemplate.tsx";
import ImageGrid from "Components/ImageGrid";
import SEO from "Components/core/SEO";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="2 Hours Into The Witness" description="I played 2 hours of The Witness and came up with an opinion" mdxType="SEO" />
    <p>{`2 Hours in is a series in which I play a game I haven’t played before for 2 hours, take a step back and talk about the things that stood out to me while playing it.`}</p>
    <p>{`This week is The Witness, a puzzle game of sorts, where the player interacts with gridded screens moving lines through a maze whose path is obscured but hinted at in various ways.`}</p>
    <p>{`When I first sat down to play the witness I had come to it with mostly a positive opinion, and 2 hours in those positive opinions have only been reinforced. In fact I only just felt like I started to sink my teeth into what the game. It kept itself fresh over those 2 hours by carefully introducing puzzle mechanics and mixtures of those mechanics, but never threaten to overwhelm or bore the player.`}</p>
    <ImageGrid mdxType="ImageGrid">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgED/9oADAMBAAIQAxAAAAHntL65WxIF/8QAGRABAAMBAQAAAAAAAAAAAAAAAQACERIT/9oACAEBAAEFAhZU7PNlZuRs7//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AYj/xAAWEQEBAQAAAAAAAAAAAAAAAAAAERL/2gAIAQIBAT8BjL//xAAWEAEBAQAAAAAAAAAAAAAAAAAxACD/2gAIAQEABj8CMt//xAAbEAEAAgIDAAAAAAAAAAAAAAABABEQMSFBUf/aAAgBAQABPyEnbXkdAauAAJqJqC5DWKf/2gAMAwEAAgADAAAAEJwv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/EIYf/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERMVH/2gAIAQIBAT8QazC+n//EABkQAQADAQEAAAAAAAAAAAAAAAEAESExUf/aAAgBAQABPxArSHohDDQ6BSFEArYpadijbEtT0junJ//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Puzzle In the Witness",
            "title": "Puzzle In the Witness",
            "src": "/static/026fe1720604c07d84cac93685c74ef6/6a068/witness-puzzle-1.jpg",
            "srcSet": ["/static/026fe1720604c07d84cac93685c74ef6/09b79/witness-puzzle-1.jpg 240w", "/static/026fe1720604c07d84cac93685c74ef6/7cc5e/witness-puzzle-1.jpg 480w", "/static/026fe1720604c07d84cac93685c74ef6/6a068/witness-puzzle-1.jpg 960w", "/static/026fe1720604c07d84cac93685c74ef6/644c5/witness-puzzle-1.jpg 1440w", "/static/026fe1720604c07d84cac93685c74ef6/0f98f/witness-puzzle-1.jpg 1920w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAEDBAUG/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAGotSfLmxif/8QAGRAAAwEBAQAAAAAAAAAAAAAAAQIDABIi/9oACAEBAAEFAvIxZdzoTTik05qAH//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AYf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFH/8QAGhABAQACAwAAAAAAAAAAAAAAAAEiMTJBkf/aAAgBAQAGPwJt16mMcYr/xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhUTFxgZH/2gAIAQEAAT8hwZa9mSH0yrtF8X7IjjVoqGQVP//aAAwDAQACAAMAAAAQl/8A/8QAFhEBAQEAAAAAAAAAAAAAAAAAABFR/9oACAEDAQE/ENI//8QAFxEAAwEAAAAAAAAAAAAAAAAAABEhUf/aAAgBAgEBPxBUbT//xAAcEAEAAgIDAQAAAAAAAAAAAAABABExUSGRobH/2gAIAQEAAT8QEqwcUDcWJW4Qr5GuhwZpz7GIZMivsY7Qpp1KmTQz/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Puzzle In the Witness",
            "title": "Puzzle In the Witness",
            "src": "/static/d9ef866ea6df6dd3231fb0472bf709c1/6a068/witness-puzzle-2.jpg",
            "srcSet": ["/static/d9ef866ea6df6dd3231fb0472bf709c1/09b79/witness-puzzle-2.jpg 240w", "/static/d9ef866ea6df6dd3231fb0472bf709c1/7cc5e/witness-puzzle-2.jpg 480w", "/static/d9ef866ea6df6dd3231fb0472bf709c1/6a068/witness-puzzle-2.jpg 960w", "/static/d9ef866ea6df6dd3231fb0472bf709c1/644c5/witness-puzzle-2.jpg 1440w", "/static/d9ef866ea6df6dd3231fb0472bf709c1/0f98f/witness-puzzle-2.jpg 1920w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></p>
 </ImageGrid>
    <p>{`The puzzles premise is easy to grasp. Move a line, sometimes two, through a 2D maze. The fact you move in a 3D world moving between 2D puzzles did make me question if the 3D movement was even necessary.`}</p>
    <p>{`And to my pleasant surprise I was proven very wrong as the puzzles started to get more complex they started to integrate into the 3D environment. Having the player solving the puzzles using the clues given by the world around them.`}</p>
    <p>{`An obvious example of this was the shadow puzzles, which had the player avoiding the shadows being cast onto the puzzle to reach the end. One puzzle that really stuck out to me was where the player had to draw outlines around rock formations, a simple premise but one that was augmented by a damaged puzzle being twisted into the wrong direction. This then hints at the player to adjust their view of the puzzle to find the correct rock formation.`}</p>
    <p>{`These subtle clues and hints scattered all over the world really put into perspective the amount of effort and testing that had gone into every puzzle.`}</p>
    <ImageGrid mdxType="ImageGrid">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBBf/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABnz4sk2G//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAECBBARM//aAAgBAQABBQKo1qz3Ijx//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bh//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/Aaf/xAAaEAABBQEAAAAAAAAAAAAAAAABAAIREiBB/9oACAEBAAY/Aqk8Toz/AP/EABoQAAIDAQEAAAAAAAAAAAAAAAERACFBEDH/2gAIAQEAAT8hOTUzOXBFhhyp6muf/9oADAMBAAIAAwAAABA73//EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxAA7P/EABcRAAMBAAAAAAAAAAAAAAAAAAEQETH/2gAIAQIBAT8QvCv/xAAbEAEAAwEAAwAAAAAAAAAAAAABABFRMSFxof/aAAgBAQABPxBZWrRBXAOVsufJCI+KMnpGpHZ80it9n//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Puzzle In the Witness",
            "title": "Puzzle In the Witness",
            "src": "/static/5bbb74cfdb812b358c09c32838832866/6a068/witness-puzzle-3.jpg",
            "srcSet": ["/static/5bbb74cfdb812b358c09c32838832866/09b79/witness-puzzle-3.jpg 240w", "/static/5bbb74cfdb812b358c09c32838832866/7cc5e/witness-puzzle-3.jpg 480w", "/static/5bbb74cfdb812b358c09c32838832866/6a068/witness-puzzle-3.jpg 960w", "/static/5bbb74cfdb812b358c09c32838832866/644c5/witness-puzzle-3.jpg 1440w", "/static/5bbb74cfdb812b358c09c32838832866/0f98f/witness-puzzle-3.jpg 1920w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQL/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABjZH3JogK/8QAGBABAQEBAQAAAAAAAAAAAAAAAQIAERL/2gAIAQEAAQUCYgx5nVJ0cr3rv//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/AWf/xAAWEQADAAAAAAAAAAAAAAAAAAABECH/2gAIAQIBAT8BEX//xAAbEAACAQUAAAAAAAAAAAAAAAAAASECECIxkf/aAAgBAQAGPwKVweKIpd9n/8QAGhAAAwEBAQEAAAAAAAAAAAAAAAERMSFBYf/aAAgBAQABPyGxS+BNEpjVH+R8gx6yx0dtH//aAAwDAQACAAMAAAAQr/8A/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/ENqBbf/EABcRAQADAAAAAAAAAAAAAAAAAAABIUH/2gAIAQIBAT8Qnpb/xAAeEAEBAAEDBQAAAAAAAAAAAAABEQAhMUFxgZGh0f/aAAgBAQABPxB0x47BNd++Dy5qF4XHqO8EB6yXR64Iblc+wZ//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Puzzle In the Witness",
            "title": "Puzzle In the Witness",
            "src": "/static/e6a5afb21b02eaf363e6ebb9d29c8088/6a068/witness-puzzle-4.jpg",
            "srcSet": ["/static/e6a5afb21b02eaf363e6ebb9d29c8088/09b79/witness-puzzle-4.jpg 240w", "/static/e6a5afb21b02eaf363e6ebb9d29c8088/7cc5e/witness-puzzle-4.jpg 480w", "/static/e6a5afb21b02eaf363e6ebb9d29c8088/6a068/witness-puzzle-4.jpg 960w", "/static/e6a5afb21b02eaf363e6ebb9d29c8088/644c5/witness-puzzle-4.jpg 1440w", "/static/e6a5afb21b02eaf363e6ebb9d29c8088/0f98f/witness-puzzle-4.jpg 1920w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAgAE/8QAFgEBAQEAAAAAAAAAAAAAAAAABAEC/9oADAMBAAIQAxAAAAELLtmRCMr/xAAaEAABBQEAAAAAAAAAAAAAAAABAAIDETEh/9oACAEBAAEFArAWmlF0jHON/wD/xAAYEQADAQEAAAAAAAAAAAAAAAAAARECIf/aAAgBAwEBPwHC7So//8QAGBEAAgMAAAAAAAAAAAAAAAAAABECAyH/2gAIAQIBAT8BsliEf//EABYQAAMAAAAAAAAAAAAAAAAAAAAgUf/aAAgBAQAGPwIi/wD/xAAaEAACAwEBAAAAAAAAAAAAAAAAEQEhQTGR/9oACAEBAAE/IYgZ4JSEwdon1uij6bJ//9oADAMBAAIAAwAAABC4P//EABgRAAIDAAAAAAAAAAAAAAAAAAABEVFh/9oACAEDAQE/EFuWzI//xAAXEQADAQAAAAAAAAAAAAAAAAAAARFR/9oACAECAQE/EHKOFH//xAAbEAEBAAIDAQAAAAAAAAAAAAABEQAhMVFhof/aAAgBAQABPxCENPBjAiQVl+ZxhPZAcZL0aXvNQ8mLlLfP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Puzzle In the Witness",
            "title": "Puzzle In the Witness",
            "src": "/static/9b84e2b5dcf94c2ae618e960bf71b46b/6a068/witness-puzzle-5.jpg",
            "srcSet": ["/static/9b84e2b5dcf94c2ae618e960bf71b46b/09b79/witness-puzzle-5.jpg 240w", "/static/9b84e2b5dcf94c2ae618e960bf71b46b/7cc5e/witness-puzzle-5.jpg 480w", "/static/9b84e2b5dcf94c2ae618e960bf71b46b/6a068/witness-puzzle-5.jpg 960w", "/static/9b84e2b5dcf94c2ae618e960bf71b46b/644c5/witness-puzzle-5.jpg 1440w", "/static/9b84e2b5dcf94c2ae618e960bf71b46b/0f98f/witness-puzzle-5.jpg 1920w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAQBAv/aAAwDAQACEAMQAAABRs2/MiTDq//EABkQAAIDAQAAAAAAAAAAAAAAAAABAhExA//aAAgBAQABBQK0JDSOO4Sk7//EABkRAAEFAAAAAAAAAAAAAAAAAAABAxESIf/aAAgBAwEBPwFtNsSf/8QAGBEAAgMAAAAAAAAAAAAAAAAAABECAyH/2gAIAQIBAT8BtliEf//EABYQAAMAAAAAAAAAAAAAAAAAAAAgUf/aAAgBAQAGPwIi/wD/xAAcEAEAAQQDAAAAAAAAAAAAAAABABEhQVExkbH/2gAIAQEAAT8hA11EWyURDc8iVVwQ4U1MzP/aAAwDAQACAAMAAAAQ3+//xAAXEQEBAQEAAAAAAAAAAAAAAAABABFR/9oACAEDAQE/EDNJHL//xAAXEQEBAQEAAAAAAAAAAAAAAAABACFR/9oACAECAQE/EExhdv/EABwQAQEBAAEFAAAAAAAAAAAAAAERACExQWGBkf/aAAgBAQABPxAsD4GoOQEQR1Ql8IzMq0L23QELXvBfPf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Puzzle In the Witness",
            "title": "Puzzle In the Witness",
            "src": "/static/29139fe20db1e27c60f06654e20a09d0/6a068/witness-puzzle-6.jpg",
            "srcSet": ["/static/29139fe20db1e27c60f06654e20a09d0/09b79/witness-puzzle-6.jpg 240w", "/static/29139fe20db1e27c60f06654e20a09d0/7cc5e/witness-puzzle-6.jpg 480w", "/static/29139fe20db1e27c60f06654e20a09d0/6a068/witness-puzzle-6.jpg 960w", "/static/29139fe20db1e27c60f06654e20a09d0/644c5/witness-puzzle-6.jpg 1440w", "/static/29139fe20db1e27c60f06654e20a09d0/0f98f/witness-puzzle-6.jpg 1920w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></p>
 </ImageGrid>
    <p>{`The puzzles weren’t the only thing that had so much effort put into in, it is impossible to miss the beautiful environments the game takes place in. I love the colourful art style and more than once did I stop to take it all in.`}</p>
    <p>{`Sometimes I even had to take a double take, almost as if I caught something from the corner of my eye, pieces of art were strewn all over, all you had to do was look a little bit harder. Like the mermaid statue by the sea, whose face you couldn’t quite see from shore, or the piece of junk steel left by the side of a beaten path that if you blinked you would miss the sun catching it to reveal a hidden field of flowers.`}</p>
    <ImageGrid mdxType="ImageGrid">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUCAwT/xAAXAQADAQAAAAAAAAAAAAAAAAABAgME/9oADAMBAAIQAxAAAAFlYrZZ6RMIw//EABsQAQEAAQUAAAAAAAAAAAAAAAECEQADEjFB/9oACAEBAAEFAjAd6YnOyrfl08v/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwGI/8QAGBAAAgMAAAAAAAAAAAAAAAAAEBEAATH/2gAIAQEABj8CpFMbP//EABgQAQADAQAAAAAAAAAAAAAAAAEAESFR/9oACAEBAAE/IUbTYPGRlkJeSTkcxLbU/9oADAMBAAIAAwAAABD0z//EABcRAQADAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8QmmP/xAAWEQEBAQAAAAAAAAAAAAAAAAABEQD/2gAIAQIBAT8QYZhTf//EABgQAAMBAQAAAAAAAAAAAAAAAAABESFB/9oACAEBAAE/EF9pU3YOrD4ZtiO9Rp1UZyxBVSD/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Puzzle In the Witness",
            "title": "Puzzle In the Witness",
            "src": "/static/7d7187f5574ac7e13b97be2ddf046ca0/6a068/witness-puzzle-7.jpg",
            "srcSet": ["/static/7d7187f5574ac7e13b97be2ddf046ca0/09b79/witness-puzzle-7.jpg 240w", "/static/7d7187f5574ac7e13b97be2ddf046ca0/7cc5e/witness-puzzle-7.jpg 480w", "/static/7d7187f5574ac7e13b97be2ddf046ca0/6a068/witness-puzzle-7.jpg 960w", "/static/7d7187f5574ac7e13b97be2ddf046ca0/644c5/witness-puzzle-7.jpg 1440w", "/static/7d7187f5574ac7e13b97be2ddf046ca0/0f98f/witness-puzzle-7.jpg 1920w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEDAgT/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAFcylnUjAz/xAAYEAEBAQEBAAAAAAAAAAAAAAABAAIRA//aAAgBAQABBQLIXtxeFlbU3//EABYRAQEBAAAAAAAAAAAAAAAAAAAREv/aAAgBAwEBPwHVV//EABURAQEAAAAAAAAAAAAAAAAAABIQ/9oACAECAQE/AQZ//8QAGBAAAgMAAAAAAAAAAAAAAAAAAREAIDH/2gAIAQEABj8CLM11/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAEhETFR/9oACAEBAAE/IcrJnHgiNQPbg1W9PY1P/9oADAMBAAIAAwAAABA73//EABYRAQEBAAAAAAAAAAAAAAAAAAEQMf/aAAgBAwEBPxAOCP/EABcRAAMBAAAAAAAAAAAAAAAAAAABETH/2gAIAQIBAT8QrTIf/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARITFR/9oACAEBAAE/ENSRvsiSApa5vktoYLALLLjXTpyJfZ//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Puzzle In the Witness",
            "title": "Puzzle In the Witness",
            "src": "/static/30ac257a19c9ef2568346a39e2a18f35/6a068/witness-puzzle-8.jpg",
            "srcSet": ["/static/30ac257a19c9ef2568346a39e2a18f35/09b79/witness-puzzle-8.jpg 240w", "/static/30ac257a19c9ef2568346a39e2a18f35/7cc5e/witness-puzzle-8.jpg 480w", "/static/30ac257a19c9ef2568346a39e2a18f35/6a068/witness-puzzle-8.jpg 960w", "/static/30ac257a19c9ef2568346a39e2a18f35/644c5/witness-puzzle-8.jpg 1440w", "/static/30ac257a19c9ef2568346a39e2a18f35/0f98f/witness-puzzle-8.jpg 1920w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABUBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAFFufsg4Tyn/8QAGRAAAwADAAAAAAAAAAAAAAAAAAECESEx/9oACAEBAAEFAopojY4kjmRtn//EABYRAQEBAAAAAAAAAAAAAAAAAAARAv/aAAgBAwEBPwHUR//EABYRAAMAAAAAAAAAAAAAAAAAAAIQEf/aAAgBAgEBPwEav//EABgQAAIDAAAAAAAAAAAAAAAAAAARICEi/9oACAEBAAY/AkaqP//EABsQAAMBAAMBAAAAAAAAAAAAAAABETFBUaHB/9oACAEBAAE/IUGH8GvgnpZqHFdlWn//2gAMAwEAAgADAAAAEFT/AP/EABgRAAMBAQAAAAAAAAAAAAAAAAABEXGx/9oACAEDAQE/ELVJGOn/xAAXEQEBAQEAAAAAAAAAAAAAAAABABFh/9oACAECAQE/EAMVt7f/xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFhcf/aAAgBAQABPxB9hC7rfqdQ1ic+Iu92Rrva0wE5S/3P/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Puzzle In the Witness",
            "title": "Puzzle In the Witness",
            "src": "/static/979b58a628c8bfbead1abb73f9da8910/6a068/witness-puzzle-9.jpg",
            "srcSet": ["/static/979b58a628c8bfbead1abb73f9da8910/09b79/witness-puzzle-9.jpg 240w", "/static/979b58a628c8bfbead1abb73f9da8910/7cc5e/witness-puzzle-9.jpg 480w", "/static/979b58a628c8bfbead1abb73f9da8910/6a068/witness-puzzle-9.jpg 960w", "/static/979b58a628c8bfbead1abb73f9da8910/644c5/witness-puzzle-9.jpg 1440w", "/static/979b58a628c8bfbead1abb73f9da8910/0f98f/witness-puzzle-9.jpg 1920w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></p>
    </ImageGrid>
    <p>{`2 Hours In and I’m confident I could play it for a couple more, but I also feel like what I have seen has sated me, I got a small taste of what The Witness had to offer and if I played anymore those tastes might start to sour.`}</p>
    <p>{`Maybe one day I will go back and complete it, finding out all the mysteries the game hints at. Though most likely I will just enjoy my short but pleasant experience as The Witness.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      